<template lang="pug">
    .w-full
        .heading-submenu.w-full.flex.items-center.justify-between
            h2.subheading Daftar Riwayat Lokasi
            .flex.items-center.gap-2
                
                .block.w-54(v-if="detail.msisdn && detail.msisdn.length > 0")
                    .field.field--icon-right.w-full
                        select.field-style(v-model="selected_msisdn" @change="changeMsisdn()")
                            option(v-for="(item,index) in detail.msisdn" :key="index") {{ item }}
                        span.icon-field
                            b-icon(name="chevron-down")

                .box-calendar.my-4.w-64
                    b-icon(name="calendar")
                    date-range-picker(
                        v-if="dateRange.startDate && dateRange.endDate"
                        ref="picker"
                        v-model="dateRange"
                        @update="pickerUpdate($event)"
                        :locale-data="{ firstDay: 1, format: 'dd mmmm yyyy' }"
                        :date-format="dateFormatPicker"
                        opens="left"
                        :ranges="default_ranges"
                        :autoApply="true"
                    )
                        template(slot="input" slot-scope="picker") {{ picker.startDate | date }} - {{ picker.endDate | date }}
        .card-bg-theme.p-6.rounded.shadow.w-full
            table.main-table
                thead
                    tr.uppercase
                        th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                tbody
                    tr.text-xs(v-if="status_detail === 'loading' || status_location_history.status === 'loading'")
                        td.text-center(:colspan="tableThead.length")
                            .relative.p-10
                                b-loading(text="Memuat data")
                    
                    template(v-else-if="location_history && location_history.result && Object.keys(location_history.result).length > 0")
                        tr.text-xs(v-for="(data,index) in location_history.result" :key="index")
                            td {{ data.record_time ? data.record_time.substr(0, 10) : '-'}}
                            td {{ data.msisdn ? data.msisdn : '-'}}
                            td {{ data.network ? data.network : '-'}}
                            td {{ data.plmn ? data.plmn : '-'}}
                            td {{ data.country ? data.country : '-'}}
                            td {{ data.imsi ? data.imsi : '-'}}
                            td {{ data.rat_name ? data.rat_name : '-'}}
                            td {{ data.lac ? data.lac : '-'}}
                            td {{ data.cci ? data.cci : '-'}}
                            td {{ data.latitude ? data.latitude : '-'}}
                            td {{ data.longitude ? data.longitude : '-'}}
                    
                    tr.text-xs(v-else)
                        td.text-center.p-10(:colspan="tableThead.length") Data tidak ditemukan.
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
    name: 'MsisdnlocationHistory',
    components: {
        DateRangePicker
    },
    data() {
        return {
            tableThead: [
                {
                    name: 'Date',
                    // number: true,
                },
                {
                    name: 'MSISDN',
                },{
                    name: 'Network',
                },{
                    name: 'PLMN',
                },{
                    name: 'Country',
                },{
                    name: 'IMSI',
                },{
                    name: 'RAT Name',
                },{
                    name: 'LAC',
                },{
                    name: 'CCI',
                },{
                    name: 'Latitude',
                },{
                    name: 'Longitude',
                },
            ],

            selected_msisdn: '',

            dateRange: {
                // startDate: moment().subtract(2, 'months').toDate(),
                // startDate: moment().toDate(),
                startDate: this.getDefaultFromDate(),
                endDate: moment().toDate(),
            },
            default_ranges: {
                'Hari ini': [moment().toDate(), moment().toDate()],
                'Kemarin': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                '7 hari lalu': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Minggu lalu': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
        }
    },
    computed: {
        ...mapState('actor', [
            'status_detail',
            'detail',
            'status_location_history',
            'location_history',
        ]),
    },
    watch: {
        status_detail(newVal) {
            if (newVal.status === 'loading')
                this.getData(true);
        },
        detail(newVal) {
            //- console.log(newVal);
            
            //- temporary -> dummy data
            //- newVal.msisdn = '6288215243013';
            
            if (newVal && newVal.msisdn && newVal.msisdn.length > 0) {
                this.selected_msisdn = newVal.msisdn[0];
                this.getData(false);
            }
        },
    },
    methods: {
        async pickerUpdate(e) {
            // let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            // this.date_from = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            // this.date_to = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            this.getData(false);
        },
        changeMsisdn() {
            this.getData(false);
        },
        async getData(loading=false) {
            if (loading === true) {
                let status = this.status_location_history;

                if (status.status === 'loading' && status.cancel === null) {
                    status.status = false;

                } else {
                    status.status = 'loading';
                }

                this.$store.commit('actor/setStatusLocationHistory', status);
            
            } else {

                //- this.$store.dispatch('actor/getLocationHistory', [this.detail.msisdn, moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD')]);
                this.$store.dispatch('actor/getLocationHistory', [this.selected_msisdn, moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD')]);
            }
        },
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (to.name === 'MsisdnOfActorLocationHistory') {
                vm.$store.commit('actor/setLocationHistory', {});
                if (vm.detail && vm.detail.msisdn && vm.detail.msisdn.length > 0) {
                    vm.selected_msisdn = vm.detail.msisdn[0];
                    vm.getData(false);
                }
            }
        });
    },
}
</script>

<style lang="sass">

</style>